<!-- マイページ -->
<template>
  <div>
    <v-container class="text-center mb-6 mt-10" style="max-width: 1000px">
      <v-row justify="space-around">
        <v-avatar
          :color="getProfileImg ? 'white' : 'blue-grey lighten-1'"
          size="80"
        >
          <img v-if="getProfileImg" :src="getProfileImg" />
          <v-icon v-else color="white" size="60">mdi-account</v-icon>
        </v-avatar>
      </v-row>

      <v-row class="mb-5">
        <v-col>
          <div class="text--disabled">{{ me.department.name }}</div>
          <div>{{ me.nickname }}</div>
        </v-col>
      </v-row>

      <!-- 所持ポイント, 受発注総合評価 -->
      <v-row class="mb-5">
        <v-col
          cols="12"
          sm="4"
          :style="$vuetify.breakpoint.xsOnly ? '' : 'border-right: 1px solid #ccc;'"
        >
          <div class="font-weight-bold">所持ポイント</div>
          <div>
            <template v-if="summary.totalReward === null">
              <base-progress-circular />
            </template>
            <template v-else>
              <span class="text-h4 red--text text--lighten-1 font-weight-bold">
                {{ intcomma(summary.totalReward) }}
              </span>
              <span class="red--text text--lighten-1 font-weight-bold ml-1">
                ポイント
              </span>
            </template>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="font-weight-bold">ありがとう平均（受注）</div>
          <div class="d-flex align-center justify-center">
            <template v-if="summary.totalChallengerEvaluation === null">
              <base-progress-circular />
            </template>
            <template v-else>
              <base-star />
              <span class="font-weight-bold text-h5 ml-1">
                {{ Number(summary.totalChallengerEvaluation).toFixed(1) }}
              </span>
            </template>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          :style="$vuetify.breakpoint.xsOnly ? '' : 'border-left: 1px solid #ccc;'"
        >
          <div class="font-weight-bold">ありがとう平均（発注）</div>
          <div class="d-flex align-center justify-center">
            <template v-if="summary.totalClientEvaluation === null">
              <base-progress-circular />
            </template>
            <template v-else>
              <base-star />
              <span class="font-weight-bold text-h5 ml-1">
                {{ Number(summary.totalClientEvaluation).toFixed(1) }}
              </span>
            </template>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12">
          <v-card class="outset rounded-xl">
            <v-tabs
              v-model="tab"
              slider-size="3"
              grow
              hide-slider
            >
              <v-tab v-for="item in items" :key="item.tab">
                {{ item.tab }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in items" :key="item.name">
                <!-- ■■■■■■■■■■■■■■ 受注実績タブ ■■■■■■■■■■■■■■ -->
                <v-card v-if="item.name === 'receive'" :style="styles.app" flat>
                  <v-card-text>
                    <!-- 受発注実績 -->
                    <v-row justify="center" dense>
                      <v-col cols="12" sm="4">
                        <primary-card
                          :class-name="'mx-3 mb-3'"
                          :loading="summary.totalApplyCount === null"
                        >
                          <template #title>
                            応募数
                          </template>
                          <template #content>
                            <span class="text-h4 font-weight-bold me-1">{{ summary.totalApplyCount }}</span>
                            <span class="text-h5 font-weight-bold pt-1">件</span>
                          </template>
                        </primary-card>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <primary-card
                          :class-name="'mx-3 mb-3'"
                          :loading="summary.totalReceiveCount === null"
                        >
                          <template #title>
                            受注実績
                          </template>
                          <template #content>
                            <span class="text-h4 font-weight-bold me-1">{{ summary.totalReceiveCount }}</span>
                            <span class="text-h5 font-weight-bold pt-1">件</span>
                          </template>
                        </primary-card>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <primary-card
                          :class-name="'mx-3 mb-3'"
                          :loading="summary.totalReceiveLate === null"
                        >
                          <template #title>
                            受注率
                          </template>
                          <template #content>
                            <span class="text-h4 font-weight-bold me-1">{{ Number(summary.totalReceiveLate).toFixed(1) }}</span>
                            <span class="text-h5 font-weight-bold pt-2">%</span>
                          </template>
                        </primary-card>
                      </v-col>
                    </v-row>

                    <!-- 受注クエスト一覧 -->
                    <receive-list
                      :items="receiveList"
                      :loading="dataTableLoading.receive"
                      @on-click-row="onClickReceiveListRow"
                      @on-loading="onLoading({ receive: $event })"
                      @on-see-more="onSeeMoreReceiveList"
                      @on-selected-client="onSelectedClient"
                    />

                    <v-divider class="my-5" />

                    <!-- 応募クエスト一覧 -->
                    <v-card :style="styles.app" flat>
                      <v-card-title>
                        <div style="border-left: 5px solid #fa9f00;" class="ps-3">応募クエスト一覧</div>
                      </v-card-title>
                      <v-card-text>
                        <v-data-table
                          :headers="applyListHeader"
                          :items="applyList.results"
                          :items-per-page="-1"
                          :loading="dataTableLoading.apply"
                          :loading-text="'データを読み込んでいます'"
                          :no-data-text="'応募中のクエストはありません'"
                          :style="styles.app"
                          hide-default-footer
                          @click:row="onClickApplyListRow"
                          style="cursor: pointer;"
                        >
                          <template v-slot:[`item.quest.title`]="{ item }">
                            <div class="py-2">
                              <div class="caption text--disabled">{{ item.quest.department.name }}</div>
                              <div class="font-weight-bold">{{ item.quest.title }}</div>
                              <div class="caption mt-2">
                                <span
                                  style="cursor: pointer;"
                                  @click.prevent.stop="onSelectedClient(item.quest.client)"
                                >
                                  <v-avatar
                                    class="me-1"
                                    :color="getProfileImgByToken(item.quest.client.token) ? 'white' : 'blue-grey lighten-1'"
                                    size="20"
                                  >
                                    <img v-if="getProfileImgByToken(item.quest.client.token)" :src="getProfileImgByToken(item.quest.client.token)" />
                                    <v-icon v-else color="white" size="20">mdi-account</v-icon>
                                  </v-avatar>
                                  <span class="link">{{ item.quest.client.nickname }}</span>
                                </span>
                              </div>
                            </div>
                          </template>

                          <template v-slot:[`item.createdAt`]="{ item }">
                            {{ dateFormat(item.createdAt, 'YYYY年MM月DD日 HH時MM分') }}
                          </template>
                        </v-data-table>
                      </v-card-text>
                      <v-card-actions v-if="applyList.next">
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          :loading="dataTableLoading.apply"
                          @click="onSeeMoreApplyList"
                        >
                          さらに表示
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-card-text>
                </v-card>

                <!-- ■■■■■■■■■■■■■■ 発注実績タブ ■■■■■■■■■■■■■■ -->
                <v-card v-else-if="item.name === 'order'" :style="styles.app" flat>
                  <v-card-text>
                    <!-- 発注実績 -->
                    <v-row justify="center" dense>
                      <v-col cols="12" sm="4">
                        <primary-card
                          :class-name="'mx-3 mb-3'"
                          :loading="summary.totalRegistrationCount === null"
                        >
                          <template #title>
                            依頼数
                          </template>
                          <template #content>
                            <span class="text-h4 font-weight-bold me-1">{{ summary.totalRegistrationCount }}</span>
                            <span class="text-h5 font-weight-bold pt-1">件</span>
                          </template>
                        </primary-card>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <primary-card
                          :class-name="'mx-3 mb-3'"
                          :loading="summary.totalOrderCount === null"
                        >
                          <template #title>
                            発注実績
                          </template>
                          <template #content>
                            <span class="text-h4 font-weight-bold me-1">{{ summary.totalOrderCount }}</span>
                            <span class="text-h5 font-weight-bold pt-1">件</span>
                          </template>
                        </primary-card>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <primary-card
                          :class-name="'mx-3 mb-3'"
                          :loading="summary.totalOrderLate === null"
                        >
                          <template #title>
                            発注率
                          </template>
                          <template #content>
                            <span class="text-h4 font-weight-bold me-1">{{ Number(summary.totalOrderLate).toFixed(1) }}</span>
                            <span class="text-h5 font-weight-bold pt-2">%</span>
                          </template>
                        </primary-card>
                      </v-col>
                    </v-row>

                    <order-list
                      :items="orderList"
                      :loading="dataTableLoading.order"
                      @on-click-row="onClickOrderListRow"
                      @on-loading="onLoading({ order: $event })"
                      @on-see-more="onSeeMoreOrderList"
                      @on-selected-client="onSelectedClient"
                    />
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>

      <!-- 自己紹介 -->
      <v-row>
        <v-col cols="12">
          <v-card class="outset rounded-xl" :style="styles.app">
            <v-card-title>
              <div style="border-left: 5px solid #fa9f00;" class="ps-3">自己紹介</div>
            </v-card-title>
            <v-card-text class="text-start" v-html="nl2br(userprofile.summary)">自己紹介を表示</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- 公開プロフィールモーダル -->
    <public-profile-modal
      :show="showPublicProfile"
      :user-info="selectedClient"
      @cancel="onShowPublicProfile"
    />
  </div>
</template>

<script>
import AppMethodsMixin from '@/mixins/AppMethodsMixin';
import AppStyleMixin from '@/mixins/AppStyleMixin';
import BaseProgressCircular from '@/components/atoms/BaseProgressCircular';
import BaseStar from '@/components/atoms/BaseStar';
import OrderList from '@/components/organisms/OrderList';
import PrimaryCard from '@/components/organisms/PrimaryCard';
import PublicProfileModal from '@/components/organisms/PublicProfileModal';
import ReceiveList from '@/components/organisms/ReceiveList';

export default {
  name: 'Index',
  components: {
    BaseProgressCircular,
    BaseStar,
    OrderList,
    PrimaryCard,
    PublicProfileModal,
    ReceiveList
  },
  mixins: [AppMethodsMixin, AppStyleMixin],
  data() {
    return {
      dataTableLoading: {
        apply: false,
        receive: false,
        order: false
      },
      applyListHeader: [
        { align: 'start', text: 'タイトル', value: 'quest.title', sortable: false },
        { align: 'start', text: '応募日時', value: 'createdAt', sortable: false }
      ],
      applyList: {
        results: [],
        previous: null,
        next: null
      },
      receiveList: {
        results: [],
        previous: null,
        next: null
      },
      orderList: {
        results: [],
        previous: null,
        next: null
      },
      userprofile: {},
      summary: {
        totalApplyCount: null,
        totalChallengerEvaluation: null,
        totalClientEvaluation: null,
        totalOrderCount: null,
        totalOrderLate: null,
        totalReceiveCount: null,
        totalReceiveLate: null,
        totalRegistrationCount: null,
        totalReward: null
      },
      showPublicProfile: false,
      selectedClient: null,

      tab: null,
      items: [
        { tab: '受注実績', name: 'receive' },
        { tab: '発注実績', name: 'order' }
      ]
    };
  },
  computed: {
    me() {
      return this.$store.getters['users/getMe'];
    },
    getProfileImg() {
      return this.$store.getters['getProfileImg'];
    }
  },
  methods: {
    /** 応募中クエストの行クリックイベント */
    onClickApplyListRow(item) {
      // クエスト詳細へ
      this.onGoToDetail(item.quest.uuid);
    },
    onClickOrderListRow(item) {
      // クエスト詳細へ
      this.onGoToDetail(item.uuid);
    },
    /** クエスト詳細へ遷移 */
    onGoToDetail(questId) {
      this.$router.push({ name: 'QuestDetail', params: { questId } });
    },
    /** 受注クエストの行クリックイベント */
    onClickReceiveListRow(item) {
      // 進行状況へ
      this.$router.push({ name: 'ContractDetail', params: { contractId: item.uuid } });
    },
    /** 応募中クエストをさらに表示 */
    async onSeeMoreApplyList() {
      const queryString = this.applyList.next.split('?')[1];
      const responseData = await this.getApplyList(`?${queryString}`, true);

      // 現在表示している応募中クエストの複製リスト
      const sources = this.applyList.results.slice();

      // 取得したクエストを追加
      // TODO: 同一のクエストは除外
      responseData.results.forEach(result => { sources.push(result); });

      // 現在表示している応募中クエストを上書き
      this.$set(this.applyList, 'results', sources);
      this.$set(this.applyList, 'previous', responseData.previous);
      this.$set(this.applyList, 'next', responseData.next);
    },
    /** 応募中クエスト取得 */
    async getApplyList(queryString, isAwait=false) {
      // 非同期実行
      if (!isAwait) {
        return this.$store.dispatch('applyQuests/applied', queryString ? queryString : null);
      }

      // 同期実行
      this.dataTableLoading.apply = true;
      try {
        return await this.$store.dispatch('applyQuests/applied', queryString ? queryString : null);
      } finally {
        this.dataTableLoading.apply = false;
      }
    },
    /** 応募中クエストをさらに表示 */
    async onSeeMoreReceiveList() {
      const queryString = this.receiveList.next.split('?')[1];
      const responseData = await this.getReceiveList(`?${queryString}`, true);

      // 現在表示している応募中クエストの複製リスト
      const sources = this.receiveList.results.slice();

      // 取得したクエストを追加
      // TODO: 同一のクエストは除外
      responseData.results.forEach(result => { sources.push(result); });

      // 現在表示している応募中クエストを上書き
      this.$set(this.receiveList, 'results', sources);
      this.$set(this.receiveList, 'previous', responseData.previous);
      this.$set(this.receiveList, 'next', responseData.next);
    },
    /** 受注クエスト取得 */
    async getReceiveList(queryString, isAwait=false) {
      // 非同期実行
      if (!isAwait) {
        return this.$store.dispatch('receiveQuests/list', queryString ? queryString : null);
      }

      // 同期実行
      this.dataTableLoading.receive = true;
      try {
        return await this.$store.dispatch('receiveQuests/list', queryString ? queryString : null);
      } finally {
        this.dataTableLoading.receive = false;
      }
    },
    /** 発注クエストをさらに表示 */
    async onSeeMoreOrderList() {
      const queryString = this.orderList.next.split('?')[1];
      const responseData = await this.getOrderList(`?${queryString}`, true);

      // 現在表示している応募中クエストの複製リスト
      const sources = this.orderList.results.slice();

      // 取得したクエストを追加
      // TODO: 同一のクエストは除外
      responseData.results.forEach(result => { sources.push(result); });

      // 現在表示している応募中クエストを上書き
      this.$set(this.orderList, 'results', sources);
      this.$set(this.orderList, 'previous', responseData.previous);
      this.$set(this.orderList, 'next', responseData.next);
    },
    /** 発注クエスト取得 */
    async getOrderList(queryString, isAwait=false) {
      // 非同期実行
      if (!isAwait) {
        return this.$store.dispatch('orderQuests/list', queryString ? queryString : null);
      }

      // 同期実行
      this.dataTableLoading.order = true;
      try {
        return await this.$store.dispatch('orderQuests/list', queryString ? queryString : null);
      } finally {
        this.dataTableLoading.order = false;
      }
    },
    /** 発注者名クリック時の処理 */
    onSelectedClient(selectedClient) {
      this.selectedClient = selectedClient;
      this.onShowPublicProfile();
    },
    /** 発注者プロフィールをモーダル表示 */
    onShowPublicProfile() {
      this.showPublicProfile = !this.showPublicProfile;
    },
    onLoading(loading) {
      this.dataTableLoading = { ...this.dataTableLoading, ...loading };
    }
  },
  mounted() {
    this.showLoadingOverlay(async () => {
      // 応募中のクエストを一覧取得
      this.dataTableLoading.apply = true;
      this.getApplyList().then(
        responseData => {
          this.$set(this, 'applyList', responseData);
        })
        .finally(() => { 
          this.dataTableLoading.apply = false;
        });

      // 受注したクエストを一覧取得
      this.dataTableLoading.receive = true;
      this.getReceiveList().then(
        responseData => {
          this.$set(this, 'receiveList', responseData);
        })
        .finally(() => { 
          this.dataTableLoading.receive = false;
        });

      // 発注したクエストを一覧取得
      this.dataTableLoading.order = true;
      this.getOrderList().then(
        responseData => {
          this.$set(this, 'orderList', responseData);
        })
        .finally(() => { 
          this.dataTableLoading.order = false;
        });

      // 実績や評価のサマリーを取得
      this.$store.dispatch('users/summary').then(
        responseData => {
          this.summary = responseData;
        }
      );

      this.$store.dispatch('userprofiles/get').then(
        responseData => {
          this.userprofile = responseData;
        }
      );
    });
  }
};
</script>

<style lang="scss" scoped>
.v-tab {
  color: rgba(0, 0, 0, 0.38) !important;
  font-size: 18px;
  background: rgba(0, 0, 0, 0.1) !important;
  &:first-child {
    border-radius: 24px 0 0 0 !important;
  }
  &:last-child {
    border-radius: 0 24px 0 0 !important;
  }

  &::before {
    opacity: 0 !important;
  }
}

.v-tab--active {
  font-weight: bold;
  color: #FA9F00 !important;
  background: #f7faff !important;

  &::before {
    opacity: 0 !important;
  }
}
</style>
